import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import Section from 'components/Section';
import Box from 'components/Box';

import iconTrust from 'images/builds-trust.png';
import iconClients from 'images/clients-happy.png';
import iconTeamworkers from 'images/teamworkers.png';

import { Trans, useTranslation } from 'react-i18next';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 80px;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  color: ${(props) => props.theme.colors.tertiary};

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }
`;

const SectionHeading = styled.h2`
  font-size: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(46)};
  margin: 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(26)};
    line-height: ${rem(39)};
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 96px;

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
  }

  :nth-of-type(even) {
    flex-direction: row-reverse;

    @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
      flex-direction: column;
    }
  }

  img {
    width: 400px;
    height: 354px;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: 327px;
      height: 289px;
    }
  }
`;

const CardHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(24)};
  line-height: ${rem(36)};
  margin-bottom: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  }
`;

const CardText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
`;

const CARD_ITEMS = [
  {
    title: `home.Values.happy_clients.title`,
    text: 'home.Values.teamworkers.description',
    image: iconClients,
  },
  {
    title: 'home.Values.build_trust.title',
    text: 'home.Values.build_trust.description',
    image: iconTrust,
  },
  {
    title: 'home.Values.teamworkers.title',
    text: 'home.Values.happy_clients.description',
    image: iconTeamworkers,
  },
];

interface CardProps {
  element: {
    title: string;
    text: string;
    image: any;
  };
}

const Card = ({ element }: CardProps) => {
  const { title, text, image } = element;
  const { t } = useTranslation();

  return (
    <CardContainer>
      <img src={image} alt="" aria-hidden="true" />
      <div>
        <CardHeading>
          <Trans i18nKey={title} />
        </CardHeading>
        <CardText>{t(text)}</CardText>
      </div>
    </CardContainer>
  );
};

const Values = () => {
  return (
    <ContentWrapper>
      <Section>
        <Box>
          <SectionHeading>
            <Trans i18nKey="home.Values.title" />
          </SectionHeading>
          <Flex>
            {CARD_ITEMS.map((item) => (
              <Card element={item} key={item.title} />
            ))}
          </Flex>
        </Box>
      </Section>
    </ContentWrapper>
  );
};

export default Values;
