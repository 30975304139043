import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import Section from 'components/Section';
import Box from 'components/Box';

import iconRegister from 'images/icon-register.svg';
import iconDeposit from 'images/icon-deposit.svg';
import iconPortfolio from 'images/icon-portfolio.svg';

import { Trans, useTranslation } from 'react-i18next';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  margin-top: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  color: ${(props) => props.theme.colors.tertiary};

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }
`;

const SectionHeading = styled.h2`
  font-size: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(46)};
  margin: 0 0 16px 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(26)};
    line-height: ${rem(39)};
  }
`;

const SectionSubtitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  text-align: center;
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(30)};
  }
`;

const SectionWarning = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(11)};
  line-height: ${rem(21)};
  text-align: center;
  margin: 40px 0 0 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  img {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 7${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const CardHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  text-align: center;
  margin: 0;
`;

const CARD_ITEMS = [
  {
    text: 'home.Getting_Started.steps.1._Register',
    icon: iconRegister,
  },
  {
    text: 'home.Getting_Started.steps.2._Make_a_deposit',
    icon: iconDeposit,
  },
  {
    text: 'home.Getting_Started.steps.3._Build_your_portfolio',
    icon: iconPortfolio,
  },
];

interface CardProps {
  element: {
    text: string;
    icon: any;
  };
}

const Card = ({ element }: CardProps) => {
  const { text, icon } = element;

  const { t } = useTranslation();

  return (
    <CardContainer>
      <img src={icon} alt="" aria-hidden="true" />
      <div>
        <CardHeading>
          <Trans i18nKey={text} />
        </CardHeading>
      </div>
    </CardContainer>
  );
};

const GettingStarted = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <Section>
        <Box>
          <SectionHeading>
            <Trans i18nKey="home.Getting_Started.Content.title" />
          </SectionHeading>
          <SectionSubtitle>
            {t('home.Getting_Started.Content.subtitle')}
          </SectionSubtitle>
          <Grid>
            {CARD_ITEMS.map((item) => (
              <Card element={item} key={item.text} />
            ))}
          </Grid>
          <SectionWarning>
            {t('home.Getting_Started.Content.disclaimer')}
          </SectionWarning>
        </Box>
      </Section>
    </ContentWrapper>
  );
};

export default GettingStarted;
