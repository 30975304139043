import React from 'react';

import Hero from './components/Hero';
import Qualities from './components/Qualities';
import Values from './components/Values';
import GettingStarted from './components/GettingStarted';
import DirectSignup from 'components/DirectSignup';

const Home = () => {
  return (
    <>
      <Hero />
      <Qualities />
      <Values />
      <GettingStarted />
      <DirectSignup />
    </>
  );
};

export default Home;
