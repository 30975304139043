import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import Section from 'components/Section';
import Box from 'components/Box';

import iconLegal from 'images/icon-legal.svg';
import iconProtection from 'images/icon-protection.svg';
import iconNotifications from 'images/icon-notifications.svg';

import { Trans, useTranslation } from 'react-i18next';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  color: ${(props) => props.theme.colors.tertiary};

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }
`;

const SectionHeading = styled.h2`
  font-size: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(46)};
  margin: 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(26)};
    line-height: ${rem(39)};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 80px;
    height: 80px;
  }
`;

const CardHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  margin: 16px 0;
`;

const CardText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
`;

const CARD_ITEMS = [
  {
    title: 'home.USPs.compliant.title',
    text: 'home.USPs.compliant.description',
    icon: iconLegal,
  },
  {
    title: 'home.USPs.protection.title',
    text: 'home.USPs.protection.description',
    icon: iconProtection,
  },
  {
    title: 'home.USPs.notifications.title',
    text: 'home.USPs.notifications.description',
    icon: iconNotifications,
  },
];

interface CardProps {
  element: {
    title: string;
    text: string;
    icon: any;
  };
}

const Card = ({ element }: CardProps) => {
  const { t } = useTranslation();

  const { title, text, icon } = element;

  return (
    <CardContainer>
      <img src={icon} alt="" aria-hidden="true" />
      <CardHeading>{t(title)}</CardHeading>
      <CardText>{t(text)}</CardText>
    </CardContainer>
  );
};

const Qualities = () => {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <Section>
        <Box>
          <SectionHeading>
            <Trans i18nKey="home.USPs.title" />
          </SectionHeading>
          <Grid>
            {CARD_ITEMS.map((item) => (
              <Card element={item} key={item.title} />
            ))}
          </Grid>
        </Box>
      </Section>
    </ContentWrapper>
  );
};

export default Qualities;
