import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import ButtonCTA from 'components/ButtonCTA';
import backgroundImg from 'images/hero-background-desktop.png';
import PageHero from 'components/PageHero';

import { Trans, useTranslation } from 'react-i18next';
import NavigationColor from 'utils/constants/NavigationColor';

const HeroContentWrapper = styled.div`
  padding: 96px 0 144px 0;
  color: ${(props) => props.theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    padding: 96px 24px 144px 24px;
  }
`;

const Heading = styled.h1`
  font-size: ${rem(48)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(66)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(36)};
    line-height: ${rem(48)};
  }
`;

const Subtitle = styled.p`
  font-size: ${rem(18)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  line-height: ${rem(30)};
  margin-bottom: 40px;
  margin-top: 24px;
`;

const Hero = () => {
  const { t } = useTranslation();

  return (
    <PageHero
      navigationColorTheme={NavigationColor.LIGHT}
      backgroundImage={backgroundImg}
    >
      <HeroContentWrapper>
        <Heading>
          <Trans i18nKey="home.ATF.Title" />
        </Heading>
        <Subtitle>{t('home.ATF.Description')}</Subtitle>
        <ButtonCTA big>{t('home.ATF.cta')}</ButtonCTA>
      </HeroContentWrapper>
    </PageHero>
  );
};

export default Hero;
